import { RemixBrowser, useLocation, useMatches } from '@remix-run/react';
import { hydrate } from 'react-dom';
import * as Sentry from '@sentry/remix';
import { useEffect } from 'react';

Sentry.init({
  dsn: 'https://420a398d2f45404b8f3850db6dd62640:475d369d71cf4f06bd5061ac3e0e2bf5@o4504746799005696.ingest.sentry.io/4504746816110592',
  tracesSampleRate: 1,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.remixRouterInstrumentation(
        useEffect,
        useLocation,
        useMatches
      ),
    }),
  ],
  environment: process.env.NODE_ENV,
  enabled: process.env.NODE_ENV !== 'development',
});

hydrate(<RemixBrowser />, document);
